<template>
  <Verbrauch type="heizenergie"/>
</template>

<script>
import Verbrauch from '@/components/Verbrauch';

export default {
  components: {
    Verbrauch,
  },
};
</script>
